<template lang="pug">
form-input-container(
  :label="label"
  :message="message"
  :message-type="messageType"
)
  base-input(
    :modelValue="modelValue"
    :disabled="disabled"
    :autocomplete="autocomplete"
    :type="type"
    :clearable="clearable"
    :placeholder="placeholder"
    :icon="icon"
    :icon-tooltip="iconTooltip"
    :show-word-limit="showWordLimit"
    :max-length="maxLength"
    @enter="$emit('enter')"
    @click-button="$emit('clickButton')"
    @update:modelValue="$emit('update:modelValue', $event)"
  )
</template>

<script lang="ts">
import type { PropType } from "vue";
import { defineComponent } from "vue";

import FormInputContainer from "@/components/ui/form/container/FormInputContainer.vue";
import BaseInput from "@/components/ui/base/BaseInput.vue";
import UiIconNames from "@/components/ui/icon/UiIconNames";

export default defineComponent({
  name: "FormInput",
  components: {
    BaseInput,
    FormInputContainer,
  },
  emits: [
    'enter',
    'clickButton',
    'update:modelValue',
  ],
  props: {
    modelValue: {},
    type: {
      type: String as PropType<'number' | 'text' | 'password'>,
      default: 'text',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: UiIconNames.Icon_CircleClose,
    },
    iconTooltip: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    message: {
      type: String,
      default: "",
    },
    messageType: {
      type: String as PropType<'error'|'info'>,
      default: 'info',
    },
    autocomplete: {
      type: String,
      default: 'off',
    },
    maxLength: {
      type: Number,
      default: undefined,
    },
    showWordLimit: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss" scoped>
</style>
